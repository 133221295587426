import React from 'react'
import BlockContent from '@sanity/block-content-to-react'

const HeadlinePortableText = ({content}) => {
  const {text, Headline} = content

  return (
    <div className='container mb-26'>
      <section className='row'>
        <h2 className='mb-10 font-size-large col-11 col-md-3'>{Headline}</h2>
        <BlockContent
          blocks={text}
          className="font-size-medium article col-11 col-md-7 col-xl-5 offset-md-1 offset-0"
        />
      </section>
    </div>
  )
}

export default HeadlinePortableText
