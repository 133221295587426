import React from 'react'
import Layout from '../components/layout/layout'
import {graphql} from 'gatsby'
import HeadlinePortableText from '../components/headlinePortableText/headlinePortableText'

const BaiscText = ({data}) => {
  if (data.allSanityBasicText.nodes.length < 1) return null

  const {_rawDescription, title} = data.allSanityBasicText.nodes[0]

  return (
    <Layout title={title} className='template-basic-text'>
      <div className='container-fluid'>
        <HeadlinePortableText
          content={_rawDescription}
        />
      </div>
    </Layout>
  )
}

export default BaiscText

export const query = graphql`
  query AboutPageQuery($id: String) {
    allSanityBasicText(filter: {id: {eq: $id}}) {
      nodes {
        title
        slug {
          current
        }
        _rawDescription
      }
    }
  }
`
